<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="时间范围">
			  <el-date-picker
			      value-format="yyyy-MM-dd"
			      v-model="date_value"
			      type="daterange"
			      range-separator="至"
			      start-placeholder="开始日期"
			      end-placeholder="结束日期">
			  </el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button @click="device_list()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-download" @click="device_export()" style="margin-right: 10px;">导出</el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="deviceId" label="设备ID" ></el-table-column>
			<el-table-column prop="commandId" label="指令ID"></el-table-column>
			<el-table-column prop="commandStr" label="指令" ></el-table-column>
			<el-table-column prop="sendTime" label="指令下发时间" ></el-table-column>
			<el-table-column prop="responseTime" label="指令响应时间" ></el-table-column>
			<el-table-column prop="resultCode" label="指令响应结果" ></el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var fileDownload = require('js-file-download');
	export default{
		data(){
			return {
				form : {
					deviceNo : "",
					startPage : 1,
					pageSize : 10,
					startDate:"",
					endDate:""
				},
				total : 0,
				list : [],
				date_value:'',
				loading:false
			}
		},
		created(){
			this.device_list();
		},
		mounted(){
			// this.device_list();
		},
		methods: {
			device_list(){
				this.loading = true;
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				}else{
					this.form.startDate = "";
					this.form.endDate = "";
					// this.$message({
					// 	type: 'error',
					// 	message: "请选择时间范围"
					// });
					// return
				}
				this.$api.command_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						for( var i = 0 ; i < this.list.length ; i++ ){
							for( var k in this.list[i] ){
								if( this.list[i][k] == null || this.list[i][k] == "" ){
									this.list[i][k] = "—"
								}
							}
						}
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage( e ){
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.device_list();
			},
			device_export(){
				let data = {};
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					data.startDate = this.date_value[0];
					data.endDate = this.date_value[1];
				}else{
					data.startDate = "";
					data.endDate = "";
				}
				data.deviceNo = this.form.deviceNo;
				this.$api.command_export( data ).then( res => {
				  fileDownload( res , "指令下发列表.xls" );
				});
			}
		}
	}
	
	
</script>

<style>
</style>